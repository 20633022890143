import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

const ContentNavBlock = ({ block }) => {
  return (
    <BlockWrapper block={block}>
      <div
        className={`flex flex-row flex-wrap -mx-grid -mb-8 ${
          block.headline?.length > 0 ? "pt-8" : ""
        }`}
      >
        {block.nav_items?.length > 0 &&
          block.nav_items.map((item, index) => (
            <div
              className="w-full h-36 lg:h-60 sm:w-1/2 lg:w-1/3 mb-8 -mt-0.5 px-grid"
              key={index}
            >
              <div className="relative block h-full transition-all duration-200 shadow-md shadow-black/20 group hover:bg-primary">
                {item.image?.filename?.length > 0 && (
                  <>
                    <Image
                      image={item.image}
                      className="object-cover w-full h-full"
                    />
                    <div className="absolute inset-0 transition-all duration-200 bg-black bg-opacity-50 group-hover:bg-primary"></div>
                  </>
                )}
                <div
                  className={`absolute top-0 w-full h-full transition-all duration-200 ${
                    item.image?.filename?.length > 0
                      ? "text-white"
                      : "group-hover:text-white"
                  }`}
                >
                  <Link
                    className="block w-full h-full pt-4 px-grid"
                    link={item.link}
                  >
                    {item.title?.length > 0 && (
                      <span className="headline headline-h3 font-bold [hyphens:auto] line-clamp-2">
                        {item.title}
                      </span>
                    )}
                    {item.description?.length > 0 && (
                      <div className="hidden mt-2 lg:block">
                        <span className=" line-clamp-2">
                          {item.description}
                        </span>
                      </div>
                    )}
                    {item.link_text?.length > 0 && (
                      <span className="absolute bottom-0 block pb-4">
                        {item.link_text}
                      </span>
                    )}
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default ContentNavBlock
